<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="source">{{$t('journal_transactions.source')}}</label>
                                <select name="" id="source" v-model="filters.document_type" type="text" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option v-for="(row, index) in source_list" :value="row.slug" :key="'source'+index">{{ row.title }}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="source">{{$t('journal_transactions.journal_account')}}</label>
                                <treeselect
                                        :options="accounts_list"
                                        :load-options="loadOptions"
                                        :multiple="false"
                                        :value="filters.account_id"
                                        @input="updateValue($event)"
                                        :searchable="true"
                                        :show-count="true"
                                        :no-children-text="$t('No_sub_options')"
                                        :no-options-text="$t('No_options_available')"
                                        :no-results-text="$t('No_results_found')"
                                        :placeholder="$t('Select')">
                                </treeselect>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('journal_transactions.from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('journal_transactions.to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>




                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!-- End Filter -->
        <div class="card card-custom">
            <div class="card-body">
                <b-tabs content-class="mt-3">

                    <b-tab :title="$t('details')" active>
                        <div class="mt-3">
                            <div class="row mb-10">
                                <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                                    <h4 class="text-center">{{ $t('journal_transactions.journal_transactions')}}</h4>
                                    <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                                </div>

                            </div>
                            <div class="row mb-10">
                                <div class="col-12" v-if="data_report_details">
                                    <div class="table-responsive">
                                        <div  class="row justify-content-end p-4">
                                            <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                                <download-excel
                                                        class="dropdown-item"
                                                        :fetch="accountingDetailsExport"
                                                        :fields="json_fields_details"
                                                        :name="'journal_transactions.xls'">
                                                    <i class="la la-file-excel"></i>{{$t('excel')}}
                                                </download-excel>
                                                <button class="dropdown-item" @click="printData('summaryTableDetails')">
                                                    <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                                </button>
                                            </b-dropdown>
                                        </div>
                                        <table class="table table-bordered" id="summaryTableDetails">
                                            <thead>
                                            <tr>
                                                <th>{{$t('journal_transactions.date')}}</th>
                                                <th>{{$t('journal_transactions.number')}}</th>
                                                <th>{{$t('journal_transactions.account')}}</th>
                                                <th>{{$t('journal_transactions.description')}}</th>
                                                <th>{{$t('journal_transactions.source')}}</th>
                                                <th>{{$t('journal_transactions.debit')}} ({{currency_name}})</th>
                                                <th>{{$t('journal_transactions.credit')}} ({{currency_name}})</th>
                                                <th>{{$t('journal_transactions.before_amount')}} ({{currency_name}})</th>
                                                <th>{{$t('journal_transactions.after_amount')}} ({{currency_name}})</th>

                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="(row, index) in data_report_details">
                                                <tr :key="'a'+index">
                                                    <td colspan="7">{{row.label}}</td>
                                                </tr>
                                                <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
                                                    <td>{{row2.date}}</td>
                                                    <td>{{row2.number}}</td>
                                                    <td>{{row2.account}}</td>
                                                    <td>{{row2.description}}</td>
                                                    <td>{{row2.source}}</td>
                                                    <td>{{row2.debit}}</td>
                                                    <td>{{row2.credit}}</td>
                                                    <td>{{row2.before_amount}}</td>
                                                    <td>{{row2.after_amount}}</td>
                                                </tr>
                                                <tr :key="'b'+index">
                                                    <td colspan="5">{{$t('journal_transactions.total')}}</td>
                                                    <td>{{row.total_debit}}</td>
                                                    <td>{{row.total_credit}}</td>
                                                    <td>{{row.total_before_amount}}</td>
                                                    <td>{{row.total_after_amount}}</td>
                                                </tr>
                                            </template>

                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td colspan="5"><b>{{$t('journal_transactions.total')}}</b></td>
                                                <th>{{details_total_sum.sum_total_debit ? details_total_sum.sum_total_debit : 0}}</th>
                                                <th>{{details_total_sum.sum_total_credit ? details_total_sum.sum_total_credit : 0}}</th>
                                                <th>{{details_total_sum.sum_before_amount ? details_total_sum.sum_before_amount : 0}}</th>
                                                <th>{{details_total_sum.sum_after_amount ? details_total_sum.sum_after_amount : 0}}</th>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <!-- <b-tab> -->
                    <!-- </b-tab> -->
                </b-tabs>
                <div class="text-center">
                    <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>
                    </button>
                </div>

            </div>
        </div>
        <!--end::supplier-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-journal-transactions",
        data() {
            return {
                mainRoute: '/reports/accounting/journal_entries',
                mainRouteDependency: 'base/dependency',

                currency_name: null,
                source_list: [],
                accounts_list: [],

                data_report_details: [],
                details_total_sum: [],

                filters: {
                    document_type: null,
                    account_id: null,
                    from_date: null,
                    to_date: null,
                },
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page : 0,
            }
        },
        computed: {
            json_fields_details: function () {
                let fields = {};

                fields[this.$t('journal_transactions.date')] = 'date';
                fields[this.$t('journal_transactions.number')] = 'number';
                fields[this.$t('journal_transactions.account')] = 'account';
                fields[this.$t('journal_transactions.description')] = 'description';
                fields[this.$t('journal_transactions.source')] = 'source';
                fields[this.$t('journal_transactions.debit') + ` (${this.currency_name})`] = 'debit';
                fields[this.$t('journal_transactions.credit') + ` (${this.currency_name})`] = 'credit';
                fields[this.$t('journal_transactions.before_amount') + ` (${this.currency_name})`] = 'before_amount';
                fields[this.$t('journal_transactions.after_amount') + ` (${this.currency_name})`] = 'after_amount';

                return fields;
            },


        },
        beforeMount() {
            this.getDocumentTypeList();
            this.getAccountParents();
            this.getAuthUser();
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.journal_transactions_reports")}]);
        },
        methods: {

            accountingDetailsExport() {
                this.setReportLog('excel', 'journal transactions reports');
                let _footer = {
                    date: this.$t('journal_transactions.total') + ` (${this.currency_name})`,
                    number: '',
                    account: '',
                    description: '',
                    source: '',
                    debit: this.details_total_sum.sum_total_debit,
                    credit: this.details_total_sum.sum_total_credit,
                    before_amount: this.details_total_sum.sum_before_amount,
                    after_amount: this.details_total_sum.sum_after_amount,
                };
                let _data = [];
                this.data_report_details.forEach((row) => {
                    _data.push({
                        date: row.label,
                        number: '',
                        account: '',
                        description: '',
                        source: '',
                        debit: '',
                        credit: '',
                        before_amount: '',
                        after_amount: '',
                    });
                    row.details.forEach((sub_row) => {
                        _data.push({
                            date: sub_row.date,
                            number: sub_row.number,
                            account: sub_row.account,
                            description: sub_row.description,
                            source: sub_row.source,
                            debit: sub_row.debit,
                            credit: sub_row.credit,
                            before_amount: sub_row.before_amount,
                            after_amount: sub_row.after_amount,
                        });
                    });
                    _data.push({
                        date: this.$t('journal_transactions.total') + ` (${this.currency_name})`,
                        number: '',
                        account: '',
                        description: '',
                        source: '',
                        debit: row.total.total_debit,
                        credit: row.total.total_credit,
                        before_amount: row.total.total_before_amount,
                        after_amount: row.total.total_after_amount,
                    });
                });
                _data.push(_footer);

                return _data;
            },

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },
            doFilter() {
                this.getReportDetails();

            },
            resetFilter() {
                this.filters.document_type = null;
                this.filters.account_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;

            },
            getAuthUser() {
                ApiService.get(`/get_auth`).then((response) => {
                    this.currency_name = response.data.data.currency_name;
                });
            },
            getDocumentTypeList() {
                ApiService.get(this.mainRouteDependency + "/doc_type_list").then((response) => {
                    this.source_list = response.data.data;
                });
            },
            getAccountParents() {
                ApiService.get(`${this.mainRouteDependency}/${'account_parents'}`).then((response) => {
                    this.accounts_list = response.data.data;
                });
            },
            loadMore() {
                this.page = this.page + 1;

                this.getReportDetails();
            },
            loadOptions() {
            },
            updateValue(value) {
                this.filters.account_id = value
            },

            
            /*****
             * details
             */

            getReportDetails() {
                ApiService.get(this.mainRoute, {params: {...this.filters,page:(this.page ? this.page : 1)}}).then((response) => {
                    this.data_report_details = response.data.data;
                    this.details_total_sum = response.data.total;
                });
            },

            printData(tableId) {
                this.setReportLog('pdf', 'journal transactions reports');
                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
